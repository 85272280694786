import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule, NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';

export interface BlockingLoaderRef {
  closeSuccess: () => void,
  setError: (errorMessage: string, compensate?: () => void) => void;
  setMessage: (message: string) => void;
}

@Component({
  selector: 'rais-blocking-loader',
  standalone: true,
  imports: [CommonModule, NzSpinModule, NzModalModule, NzAlertModule],
  template: `
    <div class="loader-container">
      <nz-spin [nzSpinning]="true" [nzTip]="viewState$().message" *ngIf="viewState$().state === 'loading'"></nz-spin>
      <nz-alert
        nzType="error"
        nzMessage="Ошибка"
        nzCloseable
        [nzDescription]="viewState$().errorMessage"
        *ngIf="viewState$().state === 'error'"
        (nzOnClose)="onErrorClose()"
      ></nz-alert>
    </div>

    <div class="modal-footer" *nzModalFooter></div>
    <div *nzModalTitle></div>
  `,
  styles: [
    `
      .loader-container {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BlockingLoaderComponent {
  private nzModalRef = inject(NzModalRef);
  viewState$ = signal<{
    state: 'loading' | 'error';
    message: string;
    errorMessage: string;
  }>({ state: 'loading', message: '', errorMessage: null });

  afterErrorCloseCallback: () => void;
  constructor() {}

  onErrorClose() {
    this.afterErrorCloseCallback?.();
    this.nzModalRef.close();
  }

  static factory() {
    const modalService = inject(NzModalService);

    return (options: { message: string }): BlockingLoaderRef => {
      const modal = modalService.create<BlockingLoaderComponent, { message: string }>({
        nzContent: BlockingLoaderComponent,
        nzClosable: false,
        nzMaskClosable: false,
        nzCloseIcon: null,
      });

      modal.componentInstance.viewState$.update((o) => ({
        ...o,
        state: 'loading',
        message: options.message,
      }));
      return {
        closeSuccess: () => modal.close(),
        setError: (errorMessage: string, compensate?: () => void) => {
          modal.componentInstance.viewState$.update((o) => ({
            ...o,
            state: 'error',
            errorMessage,
          }));

          modal.componentInstance.afterErrorCloseCallback = compensate;
        },
        setMessage: (message: string) => {
          modal.componentInstance.viewState$.update(o => ({
            ...o,
            message
          }))
        }
      };
    };
  }
}
